export const ROUTE_NAMES = {
  DEFAULT: 'DEFAULT',
  DISCLAIMER: 'DISCLAIMER',
  //
  ACTIVATE: 'ACTIVATE',
  INVITE_TRANSACTIONS: 'INVITE_TRANSACTIONS',
  INVITES_MANAGE: 'INVITES_MANAGE',
  //
  LOGIN: 'LOGIN',
  LOGIN_OTHER: 'OTHER',
  REDOWNLOAD: 'REDOWNLOAD',
  RETICKET: 'RETICKET',
  PERMISSIONS: 'PERMISSIONS',
  //
  ACCEPT_TRANSFER: 'ACCEPT_TRANSFER',
  CANCEL_TRANSFER: 'CANCEL_TRANSFER',
  POINTS: 'POINTS',
  POINT: 'POINT',
  SIGIL_GENERATOR: 'SIGIL_GENERATOR',
  CREATE_GALAXY: 'CREATE_GALAXY',
  SENATE: 'SENATE',
  DOCUMENTS: 'DOCUMENTS',
  UPGRADES: 'UPGRADES',
  ISSUE_CHILD: 'ISSUE_CHILD',
  PARTY_SET_POOL_SIZE: 'PARTY_SET_POOL_SIZE',
  INVITE_COHORT: 'INVITE_COHORT',
  GENERATE_INVITES: 'GENERATE_INVITES',
  STAR_RELEASE: 'STAR_RELEASE',
  ACCEPT_LOCKUP: 'ACCEPT_LOCKUP',
  URBIT_OS: 'URBIT_OS',
  URBIT_ID: 'URBIT_ID',
  RESIDENTS: 'RESIDENTS',
  REQUESTS: 'REQUESTS',
  ADOPT: 'ADOPT',
  BITCOIN: 'BITCOIN',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  MIGRATE_L2: 'MIGRATE_L2',
  OPS: 'OPS',
};
